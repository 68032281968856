<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.0512 36.1867C55.3277 25.8915 56.0424 15.3387 52.8015 10.3769C49.5606 5.4151 39.9019 -3.13956 17.0806 11.7671C-5.74076 26.6738 0.0636806 42.8782 3.6299 48.3342C20.3197 73.8844 73.9606 54.4195 62.0512 36.1867Z" fill="#F3F7FC"/>
    <path d="M49.061 54.8922L51.5752 52.3781L52.8323 53.6352L50.3181 56.1493L49.061 54.8922Z" fill="#646995"/>
    <path d="M56.2889 47.6648L58.8031 45.1507L60.0602 46.4077L57.546 48.9219L56.2889 47.6648Z" fill="#646995"/>
    <path d="M56.2877 53.921L57.5448 52.6639L60.059 55.1781L58.8019 56.4352L56.2877 53.921Z" fill="#646995"/>
    <path d="M25.1147 9.44347C23.6444 9.44347 22.448 8.24703 22.448 6.7768C22.448 5.30658 23.6444 4.11014 25.1147 4.11014C26.5849 4.11014 27.7813 5.30658 27.7813 6.7768C27.7813 8.24703 26.5849 9.44347 25.1147 9.44347ZM25.1147 5.88792C24.624 5.88792 24.2258 6.28792 24.2258 6.7768C24.2258 7.26569 24.624 7.66569 25.1147 7.66569C25.6053 7.66569 26.0036 7.26569 26.0036 6.7768C26.0036 6.28792 25.6053 5.88792 25.1147 5.88792Z" fill="#646995"/>
    <path d="M46.2222 39.5557C47.4495 39.5557 48.4445 38.5607 48.4445 37.3334C48.4445 36.1061 47.4495 35.1112 46.2222 35.1112C44.9949 35.1112 44 36.1061 44 37.3334C44 38.5607 44.9949 39.5557 46.2222 39.5557Z" fill="#F5F6FA"/>
    <path d="M17.7778 39.5557C19.0051 39.5557 20 38.5607 20 37.3334C20 36.1061 19.0051 35.1112 17.7778 35.1112C16.5505 35.1112 15.5555 36.1061 15.5555 37.3334C15.5555 38.5607 16.5505 39.5557 17.7778 39.5557Z" fill="#F5F6FA"/>
    <path d="M32 40.4445C34.2091 40.4445 36 38.6536 36 36.4445C36 34.2353 34.2091 32.4445 32 32.4445C29.7909 32.4445 28 34.2353 28 36.4445C28 38.6536 29.7909 40.4445 32 40.4445Z" fill="#FFA400"/>
    <path d="M23.5555 52V49.3333C23.5555 47.3689 25.1467 45.7778 27.1111 45.7778H36.8889C38.8533 45.7778 40.4444 47.3689 40.4444 49.3333V52H23.5555Z" fill="#FFA400"/>
    <path d="M32.0231 24.5173L27.3618 27.1111L28.2347 21.6462L24.4445 17.792L29.6622 16.9778L31.9805 12L34.3325 16.9618L39.5556 17.7404L35.7902 21.6213L36.7022 27.0791L32.0231 24.5173Z" fill="#FFA400"/>
    <path d="M44.521 17.584L46.2934 13.7778L48.0908 17.5716L52.0854 18.1671L49.2072 21.1342L49.9041 25.3084L46.3254 23.3493L42.761 25.3333L43.4294 21.1538L44.521 17.584Z" fill="#F5F6FA"/>
    <path d="M19.479 17.584L17.7066 13.7778L15.9092 17.5716L11.9146 18.1671L14.7928 21.1342L14.0959 25.3084L17.6746 23.3493L21.239 25.3333L20.5706 21.1538L19.479 17.584Z" fill="#F5F6FA"/>
    <path d="M30.6667 45.7778H27.1111C25.1467 45.7778 23.5555 47.3689 23.5555 49.3333V52H27.1111V49.3333C27.1111 47.3689 28.7022 45.7778 30.6667 45.7778Z" fill="#FFA400"/>
    <path d="M31.5556 36.4445C31.5556 34.8765 32.4658 33.5342 33.7778 32.8782C33.2409 32.6098 32.6418 32.4445 32 32.4445C29.7902 32.4445 28 34.2347 28 36.4445C28 38.6542 29.7902 40.4445 32 40.4445C32.6418 40.4445 33.2409 40.2791 33.7778 40.0107C32.4658 39.3547 31.5556 38.0107 31.5556 36.4445Z" fill="#FFA400"/>
    <path d="M17.6746 23.3493L17.9537 23.504L18.3483 21.1342L15.4701 18.1671L19.4648 17.5716L19.4683 17.5609L17.7066 13.7778L15.9092 17.5716L11.9146 18.1671L14.7928 21.1342L14.0959 25.3084L17.6746 23.3493Z" fill="#F5F6FA"/>
    <path d="M19.4382 24.3305L21.2391 25.3332L20.9458 23.5038L19.4382 24.3305Z" fill="#F5F6FA"/>
    <path d="M43.0543 23.5038L42.761 25.3332L44.5619 24.3305L43.0543 23.5038Z" fill="#F5F6FA"/>
    <path d="M52.0855 18.1671L48.0909 17.5716L46.2935 13.7778L44.5317 17.5609L44.5353 17.5716L48.53 18.1671L45.6517 21.1342L46.0464 23.504L46.3255 23.3493L49.9042 25.3084L49.2073 21.1342L52.0855 18.1671Z" fill="#F5F6FA"/>
    <path d="M31.264 24.9387L31.7902 21.6462L28 17.792L33.2178 16.9778L33.7742 15.7831L31.9805 12L29.6622 16.9778L24.4445 17.792L28.2347 21.6462L27.3618 27.1111L31.264 24.9387Z" fill="#FFA400"/>
    <path d="M35.5787 24.5173L33.8152 25.4986L36.7023 27.079L36.3432 24.935L35.5787 24.5173Z" fill="#FFA400"/>
    <path d="M46.2225 40.8889C44.2617 40.8889 42.667 39.2942 42.667 37.3333C42.667 35.3724 44.2617 33.7778 46.2225 33.7778C48.1834 33.7778 49.7781 35.3724 49.7781 37.3333C49.7781 39.2942 48.1834 40.8889 46.2225 40.8889ZM46.2225 36.4444C45.7319 36.4444 45.3337 36.8444 45.3337 37.3333C45.3337 37.8222 45.7319 38.2222 46.2225 38.2222C46.7132 38.2222 47.1114 37.8222 47.1114 37.3333C47.1114 36.8444 46.7132 36.4444 46.2225 36.4444Z" fill="#383B53"/>
    <path d="M53.3333 48.4443H50.6667V47.5554C50.6667 46.3305 49.6693 45.3332 48.4444 45.3332H44V42.6665H48.4444C51.1413 42.6665 53.3333 44.8585 53.3333 47.5554V48.4443Z" fill="#383B53"/>
    <path d="M17.778 40.8889C15.8171 40.8889 14.2224 39.2942 14.2224 37.3333C14.2224 35.3724 15.8171 33.7778 17.778 33.7778C19.7389 33.7778 21.3335 35.3724 21.3335 37.3333C21.3335 39.2942 19.7389 40.8889 17.778 40.8889ZM17.778 36.4444C17.2873 36.4444 16.8891 36.8444 16.8891 37.3333C16.8891 37.8222 17.2873 38.2222 17.778 38.2222C18.2686 38.2222 18.6669 37.8222 18.6669 37.3333C18.6669 36.8444 18.2686 36.4444 17.778 36.4444Z" fill="#383B53"/>
    <path d="M13.3335 48.4443H10.6669V47.5554C10.6669 44.8585 12.8589 42.6665 15.5558 42.6665H20.0002V45.3332H15.5558C14.3309 45.3332 13.3335 46.3305 13.3335 47.5554V48.4443Z" fill="#383B53"/>
    <path d="M32.0002 41.7779C29.0598 41.7779 26.6669 39.385 26.6669 36.4446C26.6669 33.5041 29.0598 31.1112 32.0002 31.1112C34.9406 31.1112 37.3335 33.5041 37.3335 36.4446C37.3335 39.385 34.9406 41.7779 32.0002 41.7779ZM32.0002 33.7779C30.53 33.7779 29.3335 34.9743 29.3335 36.4446C29.3335 37.9148 30.53 39.1112 32.0002 39.1112C33.4704 39.1112 34.6669 37.9148 34.6669 36.4446C34.6669 34.9743 33.4704 33.7779 32.0002 33.7779Z" fill="#383B53"/>
    <path d="M41.778 52H39.1113V49.3333C39.1113 48.1085 38.114 47.1111 36.8891 47.1111H27.1113C25.8864 47.1111 24.8891 48.1085 24.8891 49.3333V52H22.2224V49.3333C22.2224 46.6365 24.4144 44.4445 27.1113 44.4445H36.8891C39.586 44.4445 41.778 46.6365 41.778 49.3333V52Z" fill="#383B53"/>
    <path d="M27.3618 28.4443C27.0933 28.4443 26.8267 28.3625 26.5973 28.2025C26.1796 27.911 25.9645 27.4043 26.0445 26.9012L26.8125 22.1012L23.4933 18.7288C23.1431 18.3714 23.0205 17.8488 23.1787 17.3741C23.3351 16.8976 23.744 16.5528 24.2382 16.4745L28.7538 15.7705L30.7716 11.4381C30.9902 10.9688 31.4578 10.6683 31.9751 10.6665H31.9787C32.4942 10.6665 32.9636 10.9634 33.184 11.4292L35.232 15.7492L39.7511 16.4212C40.2453 16.4959 40.6578 16.839 40.8178 17.3136C40.9778 17.7865 40.8604 18.311 40.512 18.6701L37.216 22.0674L38.016 26.8621C38.0996 27.3652 37.8898 27.8719 37.472 28.167C37.056 28.4603 36.5084 28.4941 36.0604 28.2505L32.0267 26.0408L28.0089 28.2772C27.808 28.3892 27.5858 28.4443 27.3618 28.4443ZM27.216 18.7074L29.1858 20.7092C29.4827 21.0114 29.6196 21.4363 29.552 21.8559L29.1111 24.6114L31.3724 23.3528C31.7742 23.1305 32.2613 23.1288 32.6613 23.3474L34.9333 24.5919L34.4747 21.8399C34.4053 21.4239 34.5387 20.9954 34.832 20.6914L36.7893 18.6736L34.1351 18.279C33.6942 18.2132 33.3173 17.9323 33.1271 17.5305L31.9911 15.1323L30.8711 17.5376C30.6845 17.9412 30.3076 18.2239 29.8685 18.2932L27.216 18.7074Z" fill="#383B53"/>
    <path d="M42.7612 26.6667C42.4928 26.6667 42.2261 26.5849 41.9968 26.4249C41.579 26.1334 41.3639 25.6267 41.4439 25.1236L42.1105 20.944L44.7434 21.3636L44.5088 22.832L45.675 22.1831C46.0768 21.9609 46.5639 21.9591 46.9639 22.1778L48.1337 22.8196L47.8883 21.3529C47.819 20.9369 47.9523 20.5085 48.2457 20.2045L49.3176 19.1005L47.8919 18.8889C47.451 18.8231 47.0741 18.5422 46.8839 18.1405L46.3008 16.912L45.7265 18.144L43.3105 17.0187L45.083 13.2125C45.3034 12.7467 45.7728 12.4462 46.2901 12.4445H46.2937C46.8092 12.4445 47.2785 12.7413 47.499 13.2071L48.9923 16.3573L52.283 16.8462C52.7772 16.9209 53.1897 17.264 53.3497 17.7387C53.5097 18.2133 53.3923 18.736 53.0439 19.0951L50.6332 21.5787L51.2181 25.0898C51.3017 25.5929 51.0919 26.0996 50.6741 26.3947C50.2581 26.688 49.7105 26.7218 49.2625 26.4782L46.331 24.8711L43.4101 26.4978C43.2074 26.6116 42.9852 26.6667 42.7612 26.6667Z" fill="#383B53"/>
    <path d="M21.2389 26.6667C21.0167 26.6667 20.7927 26.6116 20.59 26.4978L17.6691 24.8711L14.7376 26.48C14.2878 26.7236 13.7402 26.6898 13.326 26.3965C12.91 26.1013 12.6985 25.5947 12.782 25.0916L13.3669 21.5805L10.9562 19.0969C10.6078 18.7378 10.4905 18.2151 10.6505 17.7405C10.8105 17.2658 11.2229 16.9227 11.7171 16.848L15.0078 16.3591L16.5011 13.2089C16.7216 12.7413 17.1909 12.4445 17.7065 12.4445H17.71C18.2273 12.4462 18.6967 12.7467 18.9136 13.216L20.686 17.0222L18.27 18.1476L17.6958 16.9138L17.1127 18.1422C16.9225 18.544 16.5438 18.8249 16.1047 18.8907L14.6789 19.1022L15.7509 20.2062C16.046 20.5102 16.1776 20.9369 16.1082 21.3547L15.8629 22.8213L17.0327 22.1796C17.4362 21.9591 17.9216 21.9609 18.3216 22.1849L19.4878 22.8338L19.2531 21.3653L21.886 20.9458L22.5527 25.1254C22.6327 25.6285 22.4193 26.1351 21.9998 26.4267C21.774 26.5849 21.5073 26.6667 21.2389 26.6667Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
