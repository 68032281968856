
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import {
  subscriptions,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_TITLE,
  SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE,
} from '@/subscription';

import IconsSubscription from '@/icons/other/Subscription.vue';
import { toParserDate } from '@/utils/date';

@Options({
  components: {
    IconsSubscription,
  },
  computed: {
    ...mapState(['profile']),
  },
  props: {
    plan: String,
    showExpire: Boolean,
    altTitle: Boolean,
  },
})
export default class SubscriptionInfo extends Vue {
  declare $props: {
    plan: SUBSCRIPTION_PLAN;
    showExpire: boolean;
    altTitle: boolean;
  }

  public subscriptions = subscriptions;

  public SUBSCRIPTION_PLAN_TITLE = SUBSCRIPTION_PLAN_TITLE;

  public SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE = SUBSCRIPTION_PLAN_ALTERNATIVE_TITLE;

  public getDate(date: string): string {
    const d = toParserDate(date);

    return `${d.month.short} ${d.day.day}`;
  }
}
