<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="subs-bgc" d="M48.7413 6.95821C36.448 7.18754 27.8844 0.975984 21.9609 1.08798C16.0373 1.19998 3.65687 4.83732 4.16709 32.0924C4.67732 59.3475 21.4898 63.0329 28.0071 62.912C58.5191 62.3413 70.5155 6.55109 48.7413 6.95821Z" fill="#F3F7FC"/>
    <path d="M15.386 21.621L17.9002 19.1069L19.1573 20.364L16.6431 22.8781L15.386 21.621Z" fill="#646995"/>
    <path d="M8.15637 28.8453L10.6705 26.3311L11.9276 27.5882L9.41345 30.1023L8.15637 28.8453Z" fill="#646995"/>
    <path d="M8.15796 20.0486L9.41504 18.7916L11.9292 21.3057L10.6721 22.5628L8.15796 20.0486Z" fill="#646995"/>
    <path d="M45.0666 57.6978C43.5964 57.6978 42.4 56.5013 42.4 55.0311C42.4 53.5609 43.5964 52.3645 45.0666 52.3645C46.5369 52.3645 47.7333 53.5609 47.7333 55.0311C47.7333 56.5013 46.5369 57.6978 45.0666 57.6978ZM45.0666 54.1422C44.576 54.1422 44.1777 54.5422 44.1777 55.0311C44.1777 55.52 44.576 55.92 45.0666 55.92C45.5573 55.92 45.9555 55.52 45.9555 55.0311C45.9555 54.5422 45.5555 54.1422 45.0666 54.1422Z" fill="#646995"/>
    <path d="M46.2222 30.6667C47.4495 30.6667 48.4444 29.6717 48.4444 28.4444C48.4444 27.2171 47.4495 26.2222 46.2222 26.2222C44.9949 26.2222 44 27.2171 44 28.4444C44 29.6717 44.9949 30.6667 46.2222 30.6667Z" fill="#F3F3F1"/>
    <path d="M17.7778 30.6667C19.0051 30.6667 20 29.6717 20 28.4444C20 27.2171 19.0051 26.2222 17.7778 26.2222C16.5505 26.2222 15.5555 27.2171 15.5555 28.4444C15.5555 29.6717 16.5505 30.6667 17.7778 30.6667Z" fill="#F3F3F1"/>
    <path d="M32 31.5556C34.2091 31.5556 36 29.7647 36 27.5556C36 25.3464 34.2091 23.5556 32 23.5556C29.7909 23.5556 28 25.3464 28 27.5556C28 29.7647 29.7909 31.5556 32 31.5556Z" fill="#C4D6F0"/>
    <path d="M23.5555 43.1111V40.4444C23.5555 38.48 25.1467 36.8889 27.1111 36.8889H36.8889C38.8533 36.8889 40.4444 38.48 40.4444 40.4444V43.1111H23.5555Z" fill="#C4D6F0"/>
    <path d="M30.6667 36.8889H27.1111C25.1467 36.8889 23.5555 38.48 23.5555 40.4444V43.1111H27.1111V40.4444C27.1111 38.48 28.7022 36.8889 30.6667 36.8889Z" fill="#C4D6F0"/>
    <path d="M31.5556 27.5556C31.5556 25.9876 32.4658 24.6453 33.7778 23.9893C33.2409 23.7209 32.6418 23.5556 32 23.5556C29.7902 23.5556 28 25.3458 28 27.5556C28 29.7653 29.7902 31.5556 32 31.5556C32.6418 31.5556 33.2409 31.3902 33.7778 31.1218C32.4658 30.4658 31.5556 29.1218 31.5556 27.5556Z" fill="#C4D6F0"/>
    <path d="M46.2222 32C44.2614 32 42.6667 30.4053 42.6667 28.4444C42.6667 26.4836 44.2614 24.8889 46.2222 24.8889C48.1831 24.8889 49.7778 26.4836 49.7778 28.4444C49.7778 30.4053 48.1831 32 46.2222 32ZM46.2222 27.5556C45.7316 27.5556 45.3334 27.9556 45.3334 28.4444C45.3334 28.9333 45.7316 29.3333 46.2222 29.3333C46.7129 29.3333 47.1111 28.9333 47.1111 28.4444C47.1111 27.9556 46.7129 27.5556 46.2222 27.5556Z" fill="#383B53"/>
    <path d="M53.3333 39.5555H50.6667V38.6667C50.6667 37.4418 49.6693 36.4444 48.4444 36.4444H44V33.7778H48.4444C51.1413 33.7778 53.3333 35.9698 53.3333 38.6667V39.5555Z" fill="#383B53"/>
    <path d="M17.7778 32C15.8169 32 14.2222 30.4053 14.2222 28.4444C14.2222 26.4836 15.8169 24.8889 17.7778 24.8889C19.7387 24.8889 21.3333 26.4836 21.3333 28.4444C21.3333 30.4053 19.7387 32 17.7778 32ZM17.7778 27.5556C17.2871 27.5556 16.8889 27.9556 16.8889 28.4444C16.8889 28.9333 17.2871 29.3333 17.7778 29.3333C18.2685 29.3333 18.6667 28.9333 18.6667 28.4444C18.6667 27.9556 18.2685 27.5556 17.7778 27.5556Z" fill="#383B53"/>
    <path d="M13.3334 39.5555H10.6667V38.6667C10.6667 35.9698 12.8587 33.7778 15.5556 33.7778H20V36.4444H15.5556C14.3307 36.4444 13.3334 37.4418 13.3334 38.6667V39.5555Z" fill="#383B53"/>
    <path d="M32 32.8889C29.0596 32.8889 26.6667 30.496 26.6667 27.5555C26.6667 24.6151 29.0596 22.2222 32 22.2222C34.9405 22.2222 37.3334 24.6151 37.3334 27.5555C37.3334 30.496 34.9405 32.8889 32 32.8889ZM32 24.8889C30.5298 24.8889 29.3334 26.0853 29.3334 27.5555C29.3334 29.0258 30.5298 30.2222 32 30.2222C33.4702 30.2222 34.6667 29.0258 34.6667 27.5555C34.6667 26.0853 33.4702 24.8889 32 24.8889Z" fill="#383B53"/>
    <path d="M41.7778 43.1111H39.1111V40.4444C39.1111 39.2196 38.1138 38.2222 36.8889 38.2222H27.1111C25.8862 38.2222 24.8889 39.2196 24.8889 40.4444V43.1111H22.2222V40.4444C22.2222 37.7476 24.4142 35.5556 27.1111 35.5556H36.8889C39.5858 35.5556 41.7778 37.7476 41.7778 40.4444V43.1111Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
