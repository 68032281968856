
import { Options, Vue } from 'vue-class-component';

import free from '@/icons/subscription/Free.vue';
import standard from '@/icons/subscription/Standard.vue';
import premium from '@/icons/subscription/Premium.vue';

@Options({
  components: {
    free,
    standard,
    premium,
  },
  props: {
    plan: String,
  },
})
export default class IconsSubscription extends Vue {

}
