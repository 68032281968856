<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8924 56.592C27.1893 56.592 35.6338 62.9618 41.5591 62.9618C47.4844 62.9618 59.9289 59.5556 59.9289 32.2969C59.9289 5.03824 43.1875 1.03647 36.6702 1.03647C6.1511 1.03647 -6.88535 56.592 14.8924 56.592Z" fill="#F3F7FC"/>
    <path d="M28.8186 7.18997L31.3328 4.67581L32.5898 5.93289L30.0757 8.44705L28.8186 7.18997Z" fill="#646995"/>
    <path d="M21.5905 14.4158L24.1046 11.9017L25.3617 13.1588L22.8475 15.6729L21.5905 14.4158Z" fill="#646995"/>
    <path d="M21.5901 5.61932L22.8472 4.36224L25.3613 6.8764L24.1042 8.13348L21.5901 5.61932Z" fill="#646995"/>
    <path d="M5.84867 34.0853C4.37845 34.0853 3.18201 32.8889 3.18201 31.4186C3.18201 29.9484 4.37845 28.752 5.84867 28.752C7.31889 28.752 8.51534 29.9484 8.51534 31.4186C8.51534 32.8889 7.31889 34.0853 5.84867 34.0853ZM5.84867 30.5297C5.35801 30.5297 4.95978 30.9297 4.95978 31.4186C4.95978 31.9075 5.35801 32.3075 5.84867 32.3075C6.33934 32.3075 6.73756 31.9075 6.73756 31.4186C6.73756 30.9297 6.33934 30.5297 5.84867 30.5297Z" fill="#646995"/>
    <path d="M40.8892 43.111C42.1165 43.111 43.1114 42.1161 43.1114 40.8888C43.1114 39.6615 42.1165 38.6665 40.8892 38.6665C39.6619 38.6665 38.667 39.6615 38.667 40.8888C38.667 42.1161 39.6619 43.111 40.8892 43.111Z" fill="#3AC66E"/>
    <path d="M35.1111 52V51.1111C35.1111 49.1467 36.7022 47.5556 38.6666 47.5556H43.1111C45.0755 47.5556 46.6666 49.1467 46.6666 51.1111V52H35.1111Z" fill="#F3F7FC"/>
    <path d="M20.4445 40.4444C22.6536 40.4444 24.4445 38.6536 24.4445 36.4444C24.4445 34.2353 22.6536 32.4444 20.4445 32.4444C18.2353 32.4444 16.4445 34.2353 16.4445 36.4444C16.4445 38.6536 18.2353 40.4444 20.4445 40.4444Z" fill="#3AC66E"/>
    <path d="M12 52V49.3333C12 47.3689 13.5911 45.7778 15.5556 45.7778H25.3333C27.2978 45.7778 28.8889 47.3689 28.8889 49.3333V52H12Z" fill="#3AC66E"/>
    <path d="M29.7775 12H51.9997V27.1111H29.7775L35.5552 19.5556L29.7775 12Z" fill="#3AC66E"/>
    <path d="M19.1111 45.7778H15.5556C13.5911 45.7778 12 47.3689 12 49.3333V52H15.5556V49.3333C15.5556 47.3689 17.1467 45.7778 19.1111 45.7778Z" fill="#3AC66E"/>
    <path d="M20 36.4444C20 34.8764 20.9102 33.5342 22.2222 32.8782C21.6853 32.6098 21.0862 32.4444 20.4445 32.4444C18.2347 32.4444 16.4445 34.2346 16.4445 36.4444C16.4445 38.6542 18.2347 40.4444 20.4445 40.4444C21.0862 40.4444 21.6853 40.2791 22.2222 40.0107C20.9102 39.3547 20 38.0106 20 36.4444Z" fill="#3AC66E"/>
    <path d="M42.2222 47.5556H38.6666C36.7022 47.5556 35.1111 49.1467 35.1111 51.1111V52H38.6666V51.1111C38.6666 49.1467 40.2578 47.5556 42.2222 47.5556Z" fill="#F3F7FC"/>
    <path d="M33.333 12H29.7775L35.5552 19.5556L29.7775 27.1111H33.333L39.1108 19.5556L33.333 12Z" fill="#3AC66E"/>
    <path d="M40.8892 43.111C42.1165 43.111 43.1114 42.1161 43.1114 40.8888C43.1114 39.6615 42.1165 38.6665 40.8892 38.6665C39.6619 38.6665 38.667 39.6615 38.667 40.8888C38.667 42.1161 39.6619 43.111 40.8892 43.111Z" fill="#F3F7FC"/>
    <path d="M40.8886 44.4446C38.9277 44.4446 37.333 42.8499 37.333 40.889C37.333 38.9281 38.9277 37.3335 40.8886 37.3335C42.8495 37.3335 44.4441 38.9281 44.4441 40.889C44.4441 42.8499 42.8495 44.4446 40.8886 44.4446ZM40.8886 40.0001C40.3979 40.0001 39.9997 40.4001 39.9997 40.889C39.9997 41.3779 40.3979 41.7779 40.8886 41.7779C41.3792 41.7779 41.7775 41.3779 41.7775 40.889C41.7775 40.4001 41.3792 40.0001 40.8886 40.0001Z" fill="#383B53"/>
    <path d="M47.9997 52H45.333V51.1111C45.333 49.8862 44.3357 48.8889 43.1108 48.8889H38.6664C37.4415 48.8889 36.4441 49.8862 36.4441 51.1111V52H33.7775V51.1111C33.7775 48.4142 35.9695 46.2222 38.6664 46.2222H43.1108C45.8077 46.2222 47.9997 48.4142 47.9997 51.1111V52Z" fill="#383B53"/>
    <path d="M20.4444 41.7779C17.504 41.7779 15.1111 39.385 15.1111 36.4446C15.1111 33.5041 17.504 31.1113 20.4444 31.1113C23.3849 31.1113 25.7777 33.5041 25.7777 36.4446C25.7777 39.385 23.3849 41.7779 20.4444 41.7779ZM20.4444 33.7779C18.9742 33.7779 17.7777 34.9744 17.7777 36.4446C17.7777 37.9148 18.9742 39.1112 20.4444 39.1112C21.9146 39.1112 23.1111 37.9148 23.1111 36.4446C23.1111 34.9744 21.9146 33.7779 20.4444 33.7779Z" fill="#383B53"/>
    <path d="M30.2225 52H27.5559V49.3333C27.5559 48.1084 26.5585 47.1111 25.3337 47.1111H15.5559C14.331 47.1111 13.3337 48.1084 13.3337 49.3333V52H10.667V49.3333C10.667 46.6364 12.859 44.4444 15.5559 44.4444H25.3337C28.0305 44.4444 30.2225 46.6364 30.2225 49.3333V52Z" fill="#383B53"/>
    <path d="M50.667 12H53.3336V52H50.667V12Z" fill="#383B53"/>
    <path d="M52 28.4443H29.7778C29.2711 28.4443 28.8071 28.1563 28.5831 27.7012C28.3591 27.2461 28.4107 26.7039 28.72 26.3003L33.8773 19.5554L28.72 12.8105C28.4124 12.4052 28.3591 11.8648 28.5831 11.4096C28.8071 10.9545 29.2711 10.6665 29.7778 10.6665H42.4516V13.3332H32.4747L36.6133 18.7448C36.9795 19.2248 36.9795 19.8879 36.6133 20.3661L32.4764 25.7776H50.6667V13.3332H46.5707V10.6665H52C52.736 10.6665 53.3333 11.2639 53.3333 11.9999V27.111C53.3333 27.847 52.736 28.4443 52 28.4443V28.4443Z" fill="#383B53"/>
  </svg>
  <!-- eslint-enable -->
</template>
